import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withTrans } from '../i18n/withTrans';
import * as moment from 'moment';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

function reducer(state, action) {
  return { ...state, ...action };
}

const isBrowser = typeof window !== 'undefined';

const SelectMonth = ({ t, calendarDispatch, calendarState, ...props }) => {
  const initialState = {};
  const [state, dispatch] = useReducer(reducer, initialState);

  let actualMonth = '';
  if (isBrowser) {
    actualMonth = JSON.parse(localStorage.getItem('selectedMonth'));
  }

  useEffect(() => {
    const list = [];
    for (let i = 0; i < 10; i++) {
      list.push(moment().add(i, 'M'));
    }
    dispatch({ months: list });
  }, []);

  const handleMonthChange = (value) => {
    dispatch({ selectedMonth: value });
    if (calendarState) {
      calendarDispatch({ month: moment(value) });
    }
    localStorage.setItem('selectedMonth', JSON.stringify(value));
  };

  return (
    <>
      <FormControl>
        {calendarState && (
          <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
            {t('reservationPage.selectMonth.title')}
          </InputLabel>
        )}
        <Select
          style={{ color: '#fff' }}
          labelId="demo-simple-select-standard-label"
          style={{ minWidth: 225 }}
          value={
            state.selectedMonth || actualMonth || moment().format('YYYY-MM-DD')
          }
          onChange={(event) => handleMonthChange(event.target.value)}
        >
          {(state.months || []).map((month) => (
            <MenuItem
              key={month.format('MMMM - YYYY')}
              value={month.format('YYYY-MM-DD')}
            >
              {month
                .locale(props.language === 'fr-FR' ? 'fr' : props.language)
                .format('MMMM - YYYY')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default withTrans(SelectMonth);

SelectMonth.propTypes = {
  t: PropTypes.func.isRequired,
  calendarDispatch: PropTypes.func.isRequired,
  calendarState: PropTypes.object,
  language: PropTypes.string
};
