import React from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as moment from 'moment';
import { withTrans } from '../../i18n/withTrans';
import { FiAlertCircle } from 'react-icons/fi';
const isBrowser = typeof window !== 'undefined';

const VisitorChildInfoForm = ({
  indexVisitor,
  reservationDispatch,
  reservationState,
  t
}) => {
  const newFormsList = [...reservationState.visitorsList];
  const newTicketsList = [...reservationState.ticketsList];

  const indexTicket = reservationState.ticketsList.findIndex(
    (el) => el.visitorType === 'child'
  );
  let childNb = '0';

  if (isBrowser) {
    childNb = localStorage.getItem('childNb');
  }

  const handleInputChange = (name) => (event) => {
    const errors = [];
    const { value } = event.target || { target: {} };
    switch (name) {
      case 'prenom':
        newFormsList[indexVisitor].prenom = value;
        break;
      case 'nom':
        newFormsList[indexVisitor].nom = value;
        break;
      case 'datenaissance':
        //const yearsOld = moment().diff(event, 'years');
        newFormsList[indexVisitor].datenaissance = event;
        /* newFormsList[indexVisitor].price = (yearsOld < 4) ? 0 : 10;
                newFormsList[indexVisitor].free = yearsOld < 4;
                newTicketsList[indexTicket].price = 10.00 * (JSON.parse(childNb) - 1)*/
        break;
      default:
        break;
    }

    reservationDispatch({
      visitorsList: newFormsList,
      errors_validation: errors,
      ticketsList: newTicketsList
    });
  };

  const onSelectChildAttendant = (value) => {
    newFormsList[indexVisitor].accompagnateur = value;

    reservationDispatch({
      visitorsList: newFormsList
    });
  };
  const calculateDate = (type) => {
    let date = '';
    if (type === 'min') {
      date = moment().subtract(9, 'years');
    }
    return date;
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' }
      }}
      noValidate
      autoComplete="off"
      className="child-form-wrapper"
    >
      <FormControl>
        <div className="visitor-form-fields">
          <TextField
            required
            label={t('visitors.form.first_name')}
            onChange={handleInputChange('prenom')}
            error={
              reservationState.errors_validation?.includes(indexVisitor) &&
              !newFormsList[indexVisitor].prenom
            }
            value={newFormsList[indexVisitor]?.prenom || ''}
          />
          <TextField
            required
            label={t('visitors.form.last_name')}
            error={
              reservationState.errors_validation?.includes(indexVisitor) &&
              !newFormsList[indexVisitor].nom
            }
            onChange={handleInputChange('nom')}
            value={newFormsList[indexVisitor]?.nom || ''}
          />
        </div>
        {/*<div className="datepicker-wrapper">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                       <span style={{color: reservationState.errors_validation?.includes(newFormsList[indexVisitor].visitorType) && !newFormsList[indexVisitor].datenaissance && 'red'}}>
                            {t('visitors.form.birth_date')}
                                {reservationState.errors_validation && reservationState.errors_validation.includes(newFormsList[indexVisitor].visitorType) && !newFormsList[indexVisitor].datenaissance && (
                                      <FiAlertCircle style={{ color: "red", marginLeft: 16 }} />
                                      )}
                        </span>
                        <DatePicker
                            disableFuture
                            openTo="year"
                            format="DD/MM/YYYY"
                            minDate={calculateDate('min')}
                            helperText={t('visitors.form.child_age_limit') }
                            value={newFormsList[indexVisitor].datenaissance}
                            onChange={handleInputChange("datenaissance")}
                            views={["date"]}
                        />
                    </MuiPickersUtilsProvider>
                </div>*/}
        {/*<div style={{ minWidth: 600, marginTop: 48, display: 'flex', alignItems: 'flex-end'}}>
                    <span style={{ minWidth: '25ch', color: (reservationState.errors_validation || []).includes(newFormsList[indexVisitor].visitorType) && !newFormsList[indexVisitor].accompagnateur ? 'red' : 'rgba(0, 0, 0, 0.8)'}}>
                        {t('visitors.form.supervising_adult')}
                        {(reservationState.errors_validation || []).includes(newFormsList[indexVisitor].visitorType) && !newFormsList[indexVisitor].accompagnateur && (
                            <FiAlertCircle style={{ color: "red", marginLeft: 16 }} />
                        )}
                    </span>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            style={{minWidth: 200}}
                            value={newFormsList[indexVisitor].accompagnateur || ''}
                            onChange={(event) => onSelectChildAttendant(event.target.value)}
                        >
                            {reservationState.visitorsList.filter(el => el.visitorType.includes('adult')).map(adult => (
                                <MenuItem key={`${adult.prenom} ${adult.nom}`} value={`${adult.prenom} ${adult.nom}`}>{`${adult.prenom ? adult.prenom : ''} ${adult.nom ? adult.nom : ''}`}</MenuItem>
                            ))}
                            </Select>
                    </FormControl>
                </div>*/}
      </FormControl>
    </Box>
  );
};

export default withTrans(VisitorChildInfoForm);
