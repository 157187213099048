import React from 'react';
import { IconButton, Modal } from '@material-ui/core';
import { withTrans } from '../../i18n/withTrans';
import PropTypes from 'prop-types';

const CalendarNoteModal = ({ t, state, dispatch }) => {
  const onClose = () => {
    dispatch({ showCalendarNote: false, activeStep: state.activeStep + 1 });
  };

  return (
    <Modal
      disableEscapeKeyDown
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      open={state.showCalendarNote}
      className="modal small"
    >
      <div className="modal-content">
        <p>{t('reservationPage.calendar-note')}</p>
        <p style={{ fontWeight: 'italic' }}>
          {t('reservationPage.patrimonial-days-note')}
        </p>
        <div style={{ position: 'absolute', right: 10, bottom: 15 }}>
          <IconButton onClick={onClose}>Ok</IconButton>
        </div>
      </div>
    </Modal>
  );
};

export default withTrans(CalendarNoteModal);

CalendarNoteModal.propTypes = {
  t: PropTypes.func.isRequired,
  state: PropTypes.shape({
    showCalendarNote: PropTypes.bool.isRequired,
    activeStep: PropTypes.number.isRequired
  }).isRequired,
  dispatch: PropTypes.func.isRequired
};

CalendarNoteModal.defaultProps = {
  state: PropTypes.shape({
    showCalendarNote: undefined
  })
};
