import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@material-ui/core';
import fullTicketImg from '../../assets/images/ticket-full.jpg';
import reducedTicketImg from '../../assets/images/ticket-reduced.jpg';
import childTicketImg from '../../assets/images/ticket-child.jpg';
import freeTicketImg from '../../assets/images/ticket-free.jpg';
import { withTrans } from '../../i18n/withTrans';
import moment from 'moment';

const OrderRecap = ({ reservationState, t, ...props }) => {
  const visitType = localStorage.getItem('visitType');
  const language = localStorage.getItem('language');

  const translateVisitorType = {
    full_price: 23,
    reduced_price: 16.5,
    child_price: 10,
    free_price: 0
  };

  const setTicketImg = (visitorType) => {
    let ticketImg = '';
    switch (visitorType) {
      case 'full_price':
        ticketImg = fullTicketImg;
        break;
      case 'reduced_price':
        ticketImg = reducedTicketImg;
        break;
      case 'child_price':
        ticketImg = childTicketImg;
        break;
      case 'free_price':
        ticketImg = freeTicketImg;
        break;
      default:
        ticketImg = fullTicketImg;
        break;
    }
    return ticketImg;
  };

  const visitorRender = (index, visitor) => {
    return (
      <Card key={index} className="cart-ticket">
        <CardContent>
          <div>
            <img
              width={100}
              src={setTicketImg(visitor.visitorType)}
              alt={`Billet ${
                visitor.visitorType.includes('adult')
                  ? 'adult'
                  : visitor.visitorType.includes('young')
                  ? 'young'
                  : 'child'
              }`}
            />
          </div>
          <div className="ticket-info">
            <p className="ticket-title">
              {t('landingPage.title2')} {t('landingPage.title3')}
            </p>
            <p className="ticket-visit-type">
              {t(
                `visitTypeInfos.${
                  visitor.status !== 'old'
                    ? visitType
                    : reservationState.oldReservation.visitType
                }.title`
              )}
            </p>
            <div className="ticket-meta-wrapper">
              <div className="ticket-date-language">
                <p className="ticket-date">
                  {visitor.status !== 'old'
                    ? moment(reservationState.selectedSlot.date)
                        .locale(props.language)
                        .format('dddd DD MMMM')
                    : moment(reservationState.oldReservation?.selectedSlot.date)
                        .locale(props.language)
                        .format('dddd DD MMMM')}{' '}
                  {visitor.status !== 'old'
                    ? `${reservationState.selectedSlot.heure.substring(
                        0,
                        2
                      )}h${reservationState.selectedSlot.heure.substring(2)}`
                    : `${reservationState.oldReservation.selectedSlot.heure.substring(
                        0,
                        2
                      )}h${reservationState.oldReservation.selectedSlot.heure.substring(
                        2
                      )}`}
                </p>
                <p className="ticket-language">
                  {visitor.language === 'F'
                    ? `${t('reservationPage.languageChoice.french')}`
                    : language === 'G'
                    ? `${t('reservationPage.languageChoice.english')}`
                    : `${t('reservationPage.languageChoice.spanish')}`}
                </p>
              </div>
            </div>
            <div className="recap-ticket-price">
              <p>
                {visitor.visitorType === 'free_price'
                  ? 1
                  : visitor.price /
                    translateVisitorType[visitor.visitorType]}{' '}
                {visitor.status !== 'old'
                  ? visitType === '17'
                    ? t('reservationPage.nbPersons.unique_price').toLowerCase()
                    : t(
                        `reservationPage.nbPersons.${visitor.visitorType}`
                      ).toLowerCase()
                  : reservationState.oldReservation.visitType === '17'
                  ? t('reservationPage.nbPersons.unique_price').toLowerCase()
                  : t(
                      `reservationPage.nbPersons.${visitor.visitorType}`
                    ).toLowerCase()}
              </p>
              <p>{visitor.price.toFixed(2)} €</p>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="recap-ticket-list">
      {reservationState?.oldReservation &&
        reservationState?.oldReservation?.ticketsList?.map((visitor, index) =>
          visitorRender(index, visitor)
        )}
      {reservationState?.ticketsList &&
        reservationState?.ticketsList?.map((visitor, index) =>
          visitorRender(index, visitor)
        )}
    </div>
  );
};

export default withTrans(OrderRecap);

OrderRecap.propTypes = {
  reservationState: PropTypes.shape({
    selectedSlot: PropTypes.shape({
      date: PropTypes.string,
      heure: PropTypes.string
    }),
    ticketsList: PropTypes.arrayOf(
      PropTypes.shape({
        visitorType: PropTypes.string.isRequired,
        status: PropTypes.string,
        price: PropTypes.number.isRequired
      })
    ),
    oldReservation: PropTypes.shape({
      visitType: PropTypes.string,
      selectedSlot: PropTypes.shape({
        date: PropTypes.string,
        heure: PropTypes.string
      }),
      visitorsList: PropTypes.arrayOf(
        PropTypes.shape({
          visitorType: PropTypes.string.isRequired,
          status: PropTypes.string,
          price: PropTypes.number.isRequired
        })
      ),
      ticketsList: PropTypes.arrayOf(
        PropTypes.shape({
          visitorType: PropTypes.string.isRequired,
          status: PropTypes.string,
          price: PropTypes.number.isRequired
        })
      )
    })
  }).isRequired,
  t: PropTypes.func.isRequired,
  language: PropTypes.string
};
