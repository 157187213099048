import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card } from '@material-ui/core';
import { withTrans } from '../../i18n/withTrans';
import SystemPay from './SystemPay';

const ShowPayment = ({ reservationState, reservationDispatch, t }) => {
  const [messageTitle, setMessageTitle] = useState();
  const [message, setMessage] = useState();

  const handleRedirect = () => {
    window.location.href = '/';
  };

  return (
    <Box className="payment-box">
      {message && messageTitle ? (
        <Card className="message-card">
          <h2>{t(`payment.${messageTitle}`)}</h2>
          <p>{t(`payment.${message}`)}</p>
          <button
            className="btn-secondary"
            onClick={handleRedirect}
            style={{ marginTop: '10px' }}
          >
            {t('payment.click')}
          </button>
        </Card>
      ) : (
        <Card className="payment-card">
          <h2>{t('payment.instructions')}</h2>
          <p>{`${t('payment.total')} ${reservationState.totalPrice} €`}</p>
          <div>
            <SystemPay
              state={reservationState}
              setMessageTitle={setMessageTitle}
              setMessage={setMessage}
              reservationDispatch={reservationDispatch}
            />
          </div>
        </Card>
      )}
    </Box>
  );
};

export default withTrans(ShowPayment);

ShowPayment.propTypes = {
  reservationState: PropTypes.shape({
    totalPrice: PropTypes.number.isRequired
  }).isRequired,
  reservationDispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};
